<template>
    <div class="material">
        <div class="material_screen">
            <div class="mian">
                <div class="inner_nav clearfix">
                    <div class="fl inner_nav_l">当前位置：<span @click="backFun">首页</span> > 专题素材</div>
                    <div class="fr inner_nav_r">共 <span>{{materials_num}}</span> 作品</div>
                </div>
                <router-link class="inner_banner" :to="{name:banner.url,query:{id:banner.links_id!=0?banner.links_id:''} }">
                    <img :src="banner.thumb" alt="" />
                </router-link>
            </div>
        </div>
        <div class="mian">
            <div class="list">
                <div class="daily clearfix">
                    <template v-if="list.length>0">
                        <!-- wow animate__animated animate__fadeInUp -->
                        <div class="daily_ul" v-for="(row,i) in list" :key="i">
                            <div class="daily_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in row" :key="index" @click="toDetail(item.id)">
                                <div class="daily_li_box">
                                    <img v-lazy="item.thumb" :key="item.thumb" alt="">
                                    <div class="daily_li_mask"></div>
                                    <div class="daily_li_downLoad" @click.stop="downFun(item.id)">
                                        下载
                                        <!-- <span></span>
                                        <img src="../assets/images/icon_mrtj_download@2x.png" alt=""> -->
                                    </div>
                                    <div class="daily_li_collect" v-if="item.is_collect == 0" @click.stop="collectFun(2,item.id,index)"><img src="../assets/images/weishoucang.svg" alt=""></div>
                                    <div class="daily_li_collect" v-else @click.stop="uncollectFun(2,item.id,index)"><img src="../assets/images/icon_collection@2x.svg" alt=""></div>
                                    <div class="daily_li_shop" v-if="item.is_down_cart == 0" @click.stop="jionFun(item.id)"><img src="../assets/images/icon_add@2x.svg" alt=""></div>
                                    <div class="daily_li_shop" v-else @click.stop="jionFun(item.id)"><img src="../assets/images/addqingdan1.svg" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <el-empty v-else description="暂无数据"></el-empty>
                </div>
            </div>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
        <div class="mask" v-if="isShow" @click="closeMsg">
            <collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
        </div>
    </div>
</template>

<script>
import sidebar from '../components/sidebar.vue'
import collection from '../components/collection.vue'
export default {
    name: "projectDetail",
    components:{
        sidebar,
        collection,
    },
    data(){
        return{
            banner:{},//banner

            material_total:0,//作品总数
            
            list:[],
            projectList:[],
            videoList:[],

            isShow:false,//收藏夹显示

            // 分页
            total:0,//总数
            page:1,//页数
            limit:16,//每页个数

            // 收藏参数
            type:'',//类型
            collId:'',//收藏id

            // 接收参数
            navId:'',
            navIndex:0,

            id:'',
			materials_num:'',
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.initFun(this.$route.query.id);
        this.bannerOneban();
        this.$refs.downtotal.listFun()
    },
    methods:{
        // 获取内部banner、 
		bannerOneban(){
			var that = this;
            this.$api.POST(this.$face.bannerOneban,{location:12},function(res){
                switch(res.data.links) {
                    case 0:
                        res.data.url = '';
                        break;
                    case 1:
                        res.data.url = 'material';
                        break;
                    case 2:
                        res.data.url = 'video';
                        break;
                    case 3:
                        res.data.url = 'deformation';
                        break;
                    case 4:
                        res.data.url = 'ranking';
                        break;
                    case 5:
                        res.data.url = 'dailyWord';
                        break;
                    case 6:
                        res.data.url = 'fontSearch';
                        break;
                    case 7:
                        res.data.url = 'dailyWordUpload';
                        break;
                    case 8:
                        res.data.url = 'vip';
                        break;
                    case 9:
                        res.data.url = 'project';
                        break;
                    case 10:
                        res.data.url = 'rankingDetail';
                        break;
                    case 11:
                        res.data.url = 'projectDetail';
                        break;
                }
                that.banner = res.data
            })
		},

        initFun(id){
            var that = this;
            this.$api.POST(this.$face.fontSpecialInfo,{id:id},function(res){
                that.list = that.$utile.listData(res.data.materials_lists);
                console.log(that.list)
				that.materials_num = res.data.materials_num;
            })
        },
        
        // 查看详情
        toDetail(id){
            //this.$router.push({name:'materialDetail',query:{id:id}})
            let routeUrl = this.$router.resolve({
					name: "materialDetail",
					query: {
						id: id
					}
				});
				window.open(routeUrl.href, '_blank');
        },

        //收藏列表
        collectFun(type,id,index){
            if(localStorage.getItem('access_token')){
                this.type = type;
                this.collId = id;
                this.isShow = true;
            }else{
                this.$parent.loginShow()
            }
        },
        //取消收藏
        uncollectFun(type,id,index){
            var that = this;
            var params ={
                coll_id: id,
                type: type,
            }
            this.$api.POST(this.$face.collectionColl,params,function(res){
                // that.list[index].is_collect = 0;
                that.$utile.prompt('success','取消收藏');
                that.listFun();
            })
        },

        //关闭收藏
        closeFun(e){
            this.isShow = false;
            if(e != 1){
                this.initFun(this.id)
            }
        },
        // 点击遮罩层关闭
        closeMsg(ev){
            let dom = document.getElementById("child");
            if(dom){
                if(!dom.contains(ev.target)){
                    this.isShow = false
                }
            } 
        },

        // 下载源文件
        downFun(id){
            var that = this;
            var params ={
                id: id,
            }
            this.$api.POST(this.$face.downloadDown,params,function(res){
                const a = document.createElement('a')
                //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                a.href = res.data.down_url // 完整的url则直接使用
                // document.body.appendChild(a)
                a.click()
                that.$utile.prompt('success',res.data.msg);
            })
        },

        //加入清单
        jionFun(id){
            var that = this;
            var params ={
                material_id: id,
                type: 1,
            }
            this.$api.POST(this.$face.download_cartJoin,params,function(res){
                that.$utile.prompt('success',res.msg);
                that.$refs.downtotal.listFun()
            })
        },

        //面包屑
        backFun(){
            localStorage.setItem('clearAll',1)
            this.$router.push({name:'refresh',query:{usernext:'/'}})
        },
    },
    watch:{

    }
};
</script>

<style scoped>

.material_screen {
    width: 100%;
    background: #ffffff;
    padding-bottom: 20px;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r span {
    color: #5957FF;
}

.inner_banner {
    display: block;
    margin-bottom: 21px;
}
.inner_banner img {
    width: 100%;
}

.material_search {
    width: 100%;
    height: 70px;
    background-color: #f6f7f9;
    border-radius: 10px;
    border: solid 1px #eeeeee;
    box-sizing: border-box;
    padding-right: 160px;
    position: relative;
    margin-bottom: 12px;
}
.material_search input {
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 16px;
}
.material_search input::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
}

.material_search_button{
    position: absolute;
    top: 2px;
    right: 2px;
    width: 150px;
    height: 64px;
    background-image: linear-gradient(0deg, 
    #ffc346 0%, 
    #ffdc5b 100%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.material_class {
    height: 59px;
    line-height: 59px;
    font-size: 16px;
	color: #333333;
    border-bottom: 1px solid #eeeeee;
}
.material_class>div{
    position: relative;
    margin-left: 56px;
    cursor: pointer;
}
.material_class>div:first-child{
    margin-left: 0;
}

.material_class>div.active{
    background: url(../assets/images/navbg.png) no-repeat;
    background-size: 33px 33px;
    background-position: center 34px;
    color: #5957FF;
}
.material_class>div.active span{
    display: block;
    width: 20px;
    height: 6px;
    background-color: #5957FF;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: -1px;
}


.material_class_list{
    border-bottom: 1px solid #eeeeee;
    transition: max-height 0.6s;
    max-height: 400px;
}
.material_class_list.min{
    max-height: 0;
    overflow: hidden;
}
.material_class_item{
    position: relative;
    margin-bottom: 17px;
}
.material_class_item:first-child{
    margin-top: 27px;
}
.class_list_title{
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    line-height: 30px;
}
.class_list{
    width: 100%;
    padding-left: 52px;
    padding-right: 100px;
    box-sizing: border-box;
}
.class_list.mini{
    height: 40px;
    overflow: hidden;
}
.class_list>div{
    height: 30px;
    line-height: 28px;
    font-size: 15px;
    text-align: center;
    color: #333333;
    border: solid 1px transparent;
    box-sizing: border-box;
    margin:0 2px;
    margin-bottom: 10px;
    padding: 0 10px;
    border-radius: 10px;
    cursor: pointer;
}
.class_list>div.active{
    color: #5957FF;
    border-color: #5957FF;
}
.class_list_unfold{
    position: absolute;
    height: 30px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #5957FF;
    cursor: pointer;
}
.class_list_unfold img{
    margin-left: 4px;
}

.sort_l{
    font-size: 14px;
    color: #333333;
    line-height: 54px;
}
.sort_l_box{
    position: relative;
}
.sort_l_s{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 6px;
}
.sort_l_s img{
    margin-left: 9px;
}
.sort_l_ul{
    width: 100%;
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 99;
}
.sort_l_ul>div{
    text-align: center;
    background: #FFF;
    cursor: default;
    transition: 0.3s;
}
.sort_l_ul>div:hover{
    background: #f6f7f9;
}
.sort_r{
    font-size: 14px;
    color: #333333;
    line-height: 54px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.sort_r img{
    margin: 0 6px 0 8px;
    transform: rotate(180deg);
    transition: 0.3s;
}
.sort_r.min img{
    transform: rotate(0);
}


.list{
    margin-bottom: 86px;
}
.daily{
    margin: 34px -13.33px 45px;
    display: flex;
		justify-content: center;
}
.daily_ul{
    margin: 0 13.33px;
    width: calc(100%/4 - 26.66px);
}
.daily_li{
    width: 100%;
    margin-bottom: 26.66px;
    cursor: pointer;
}
.daily_li_box{
    width: 330px;
    /* height: 218px; */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.daily_li_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    opacity: 0;
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}

.daily_li_downLoad{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 72px;
    height: 40px;
    background-color: #5957FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0;
    z-index: 34;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.daily_li_downLoad span{
    width: 1px;
    height: 18px;
    background-color: #ffffff;
    opacity: 0.38;
    margin: 0 8px;
}

.daily_li_collect,.daily_li_shop{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 15px;
    right: 62px;
    z-index: 100;
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.daily_li_shop{
    right: 15px;
}
.daily_li_collect img,.daily_li_shop img{
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.daily_li_collect:hover img,.daily_li_shop:hover img{
    opacity: 0.8;
}

.daily_li:hover .daily_li_mask{
    opacity: 1;
}
.daily_li:hover .daily_li_downLoad{
    opacity: 1;
}
.daily_li:hover .daily_li_collect{
    opacity: 1;
}
.daily_li:hover .daily_li_shop{
    opacity: 1;
}
/* 专题 */
.project{
    margin: 28px -13.33px 40px;
}
.project_li{
    width: 330px;
    height: 300px;
    margin: 0 13.33px 30px;
    padding-top: 15px;
    background: url(../assets/images/bg_tjzt2x.png) no-repeat;
    background-size: 311px auto;
    background-position: center top;
}
.project_li_box{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    width: 330px;
    height: 300px;
}
.project_li_box>img,.video_li_box>img,.daily_li_box>img{
    /* position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); */
    width: 100%;
    height: auto;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_hot{
    position: absolute;
    top: 14px;
    left: -10px;
    width: 62px;
    height: 40px;
    background-color: #5957FF;
    border-radius:10px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    z-index: 101;
    transition: 0.5s;
    padding-left: 10px;
    box-sizing: border-box;
}
.project_li:hover .project_li_hot{
    left: 15px;
    padding-left: 0px;
}
.project_li_buttom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    text-align: left;
    padding: 0 16px;
    box-sizing: border-box;
    line-height: 45px;
    color: #FFF;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: rgba(0,0,0,.4);
    opacity: 1;
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0;
    z-index: 34;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}
.project_li_collect{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 14px;
    right: 15px;
    z-index: 100;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    opacity: 0;
    cursor: pointer;
}
.project_li_collect img{
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.project_li_collect:hover img{
    
    opacity: 0.8;
}
.project_li:hover .project_li_box > img,.video_li:hover .video_li_box>img,.daily_li:hover .daily_li_box>img{
    transform: scale(1.08);
    }
.project_li:hover .project_li_buttom{
    opacity: 0;
}
.project_li:hover .project_li_mask, .project_li:hover .project_li_collect{
    opacity: 1;
}
/* 视频教程 */
.video_list{
    margin: 28px -13.33px 45px;
}
.video_li{
    margin:0 13.33px 30px;
}
.video_li_box{
    width: 330px;
    height: 218px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.video_li_mask{
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: rgba(0,0,0,.4);
    /* opacity: 0; */
    z-index: 33;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: default;
}

.video_li_height{
    position: absolute;
    top: 15px;
    left: -100px;
    width: 72px;
    height: 40px;
    background-color: #5957FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    z-index: 34;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.video_li_height span{
    width: 1px;
    height: 18px;
    background-color: #ffffff;
    opacity: 0.38;
    margin: 0 8px;
}

.video_li_collect{
    position: absolute;
    width: 40px;
    height: 40px;
    top: -70px;
    right: 15px;
    z-index: 100;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.video_li_collect img{
    width: 100%;
    height: 100%;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.video_li_play{
    position: absolute;
    top: 140%;
    left: 50%;
    z-index: 101;
    margin-left: -37.5px;
    margin-top: -37px;
    width: 74px;
    height: 75px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.video_li_collect:hover img{
    opacity: 0.8;
}

.video_li:hover .project_li_buttom{
    opacity: 0;
}
.video_li:hover .video_li_mask{
    height: 100%;
}
.video_li:hover .video_li_height{
    top: 15px;
    left: 15px;
    opacity: 1;
    transition-delay: 0.3s;
}
.video_li:hover .video_li_collect{
    top: 15px;
    transition-delay: 0.4s;
}
.video_li:hover .video_li_play{
    top: 50%;
    transition-delay: 0.3s;
}


/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.daily_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */


</style>